<template>
  <div class="row mt-10">
    <modal :adaptive="true" height="auto" name="open_to_settings_table" width="300">
      <div class="row mx-0 py-7">
        <div
          class="col-12 row mx-0 py-2 navy-blue-border-bottom-1px text-xl font-weight-bold"
        >
          <div class="col-6 text-center">{{ $t("general.colum_name") }}</div>
          <div class="col-6 text-center">{{ $t("general.add") }}</div>
        </div>
        <div class="col-12 mx-0 py-2 text-xl">
          <draggable
            v-model="fieldsToViewEdit"
            v-bind="dragOptions"
            :move="onMove"
            class="list-group py-4"
            tag="ul"
            @end="isDragging = false"
            @start="isDragging = true"
          >
            <transition-group :name="'flip-list'" type="transition">
              <div
                v-for="element in fieldsToViewEdit"
                :key="element.sort"
                class="col-12 list-group-item w-300px d-flex justify-content-between"
              >
                <span class="col-6"> {{ $t(element.name) }}</span>

                <span class="col-6 d-flex justify-content-center">
                  <input
                    v-model="element.type_status"
                    style="font-size: 10px"
                    type="checkbox"
                /></span>
              </div>
            </transition-group>
          </draggable>
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal"
          >
            {{ $t("esc.new_send_close").toUpperCase() }}
          </button>
          <button
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="onSubmitToSaveSettingTable"
          >
            {{ $t("esc.new_send") }}
          </button>
        </div>
      </div>
    </modal>

    <div v-if="isEditing || isCreating" class="col-12">
      <KTCard ref="preview" v-bind:example="true">
        <template v-slot:body>
          <div>
            <div class="row ml-5 mt-5">
              <div class="col-4">
                <custom-multi-select
                  :disabled="isEditing"
                  :is-inline="true"
                  :max="1"
                  :model.sync="form.order_id"
                  :not-list="true"
                  :options="orderOptions"
                  :required="false"
                  :title="$t('packing_list.order_number')"
                  class=""
                  name="order_id"
                />
              </div>
              <div class="col-4">
                <custom-multi-select
                  :disabled="id"
                  :max="1"
                  :model.sync="form.supplier_company_id"
                  :not-list="true"
                  :options="supplierCompanies"
                  :title="$t('waybill.current_account')"
                  input-width="150px"
                  name="waybill_current_company"
                ></custom-multi-select>
              </div>
              <div class="col-4">
                <custom-multi-select
                  :disabled="isEditing"
                  :is-inline="true"
                  :model.sync="form.order_size_and_color_id"
                  :options="colorInformations"
                  :required="false"
                  :title="$t('packing_list.color_name')"
                  class=""
                  name="order_id"
                  @save="onOrderColorSelect"
                />
              </div>
            </div>

            <table
              id="my-table"
              aria-busy="false"
              aria-colcount="13"
              class="table b-table table-borderless b-table-no-border-collapse table-''"
              role="table"
            >
              <thead class="thead-none" role="rowgroup">
                <tr></tr>
                <tr class="" role="row">
                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.po_number") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.color_name") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.color_number") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.body_size") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th
                    class="text-center border border-top-0 border-right-0 font-size-lg"
                    style="width: 95px"
                  >
                    <div>
                      <span
                        >{{ $t("packing_list.request_quantity") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th
                    class="text-center border border-top-0 border-right-0 font-size-lg"
                    style="width: 95px"
                  >
                    <div>
                      <span
                        >{{ $t("packing_list.quantity_remaining") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.d_any") }} <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.box_quantity") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.in_package") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span>{{ $t("packing_list.broken_parcel") }}</span>
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.box_weight") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>

                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.total") }} <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>
                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.total") }} +
                        {{ $t("packing_list.broken_parcel") }}<i class="text-danger"></i
                      ></span>
                    </div>
                  </th>
                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.date") }} <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>
                  <th class="text-center border border-top-0 border-right-0 font-size-lg">
                    <div>
                      <span
                        >{{ $t("packing_list.document_or_image_upload") }}
                        <i class="text-danger">*</i></span
                      >
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <!---->
                <template v-for="(item, index) in orderInformations">
                  <tr class="b-table-empty-row" role="row">
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.po_number }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.color_name }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.color_number }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.body_size }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.total_amount }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <span
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        type="text"
                      >
                        {{ item.amount }}</span
                      >
                    </td>
                    <td class="" role="cell">
                      <input
                        v-model.sync="item.d_any"
                        :placeholder="$t('esc.new_hand_enter')"
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        v-model="item.box_quantity"
                        :placeholder="$t('esc.new_hand_enter')"
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        v-model="item.in_package"
                        :placeholder="$t('esc.new_hand_enter')"
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        v-model="item.broken_parcel"
                        :placeholder="$t('esc.new_hand_enter')"
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        v-model="item.box_weight"
                        :placeholder="$t('esc.new_hand_enter')"
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        :disabled="true"
                        :placeholder="$t('esc.new_hand_enter')"
                        :value="
                          +item.box_quantity * +item.in_package + +item.broken_parcel
                        "
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <input
                        :v-model="item.broken_total_parcel"
                        :disabled="true"
                        :placeholder="$t('esc.new_hand_enter')"
                        :value="
                          item.broken_parcel > 0
                            ? Number(item.box_quantity) + 1
                            : item.box_quantity
                        "
                        class="flex-grow-1 text-sm form-control form-control-sm py-1 px-1 text-center w-100"
                        type="text"
                      />
                    </td>
                    <td class="" role="cell">
                      <date-picker-input
                        :is-valid="item.upload_date"
                        :model.sync="item.upload_date"
                        name="upload_date"
                      ></date-picker-input>
                    </td>
                    <td class="text-center" role="cell">
                      <img
                        v-show="item.image"
                        :id="`preview-content-image-${index}`"
                        alt=""
                        class="w-50px h-50px object-cover border-15px navy-blue-border-1px cursor-pointer"
                        @click="openUploadImage(`imageFileInput${index}`)"
                      />
                      <file-input
                        :ref="`imageFileInput${index}`"
                        :model.sync="item.image"
                        :name="`content-image-${index}`"
                        :title="$t('editSample.image')"
                        accept="image/*"
                        @fileChange="onDocumentChange"
                      >
                        <template v-if="!item.image" v-slot:inputDesign>
                          <div
                            class="w-100 d-flex justify-content-center"
                            @click="openUploadImage(`imageFileInput${index}`)"
                          >
                            <span
                              v-html="
                                getIconByKey('icons.waybill.waybill_entry', {
                                  class:
                                    'w-30px h-30px d-inline-block object-fill cursor-pointer',
                                })
                              "
                            ></span>
                          </div>
                        </template>
                      </file-input>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-center mt-5">
            <button-with-icon
              :icon-name="null"
              :text="$t('general.delete')"
              class="mr-3 danger-border-1px"
              size="lg"
              @onClick="deleteClick"
            ></button-with-icon>

            <button-with-icon
              :icon-name="null"
              :text="$t('general.cancel')"
              class="mr-3 danger-border-1px"
              size="lg"
              @onClick="cancelClick"
            ></button-with-icon>

            <button-with-icon
              :icon-name="null"
              :text="$t('general.clear')"
              class="mr-3 danger-border-1px"
              size="lg"
              @onClick="clearFormItems()"
            ></button-with-icon>

            <button-with-icon
              :disabled="
                form.id
                  ? !isValidToCreate
                  : !isValidToCreate || !form.order_id || !form.order_size_and_color_id
              "
              :icon-name="null"
              :text="form.id ? $t('general.edit') : $t('general.create')"
              size="lg"
              v-if="isUserGranted('PackingList', ['create'], false)"
              @onClick="storeData()"
            ></button-with-icon>
          </div>
        </template>
      </KTCard>
      <div v-if="taskItemProxy"></div>
    </div>

    <div v-if="!isCreating && !isEditing" class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="onHitBottom(null)">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-5 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  :model.sync="filters.search"
                  :placeholder="$t('general.search_filter')"
                  title="general.search"
                ></input-filter>
              </div>
              <div class="col-lg-4 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12 my-2 row px-0 mx-0">
                <div class="col-12 my-2 my-md-0">
                  <select-filter
                    :helper-text="$t('sample.status_by_filter')"
                    title="general.status_filter"
                    :model.sync="filters.status_id"
                    :values="packingListStatuses"
                  ></select-filter>
                </div>
              </div>

              <div
                class="col-xl-1 col-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click="filterResult"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    @click="exportProductPackingListTable"
                    type="button"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.export_to_excel") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-if="!isCreating && !isEditing" class="col-md-12">
      <dashboard-box :title="$t('general.packing_list')">
        <template v-slot:preview>
          <div class="fixed" style="position: fixed !important; top: 220px; right: 15px">
            <div class="w-60px h-60px" @click="openPopToSettingTable">
              <span
                v-html="
                  getIconByKey('icons.waybill.edit', {
                    class: 'w-40px h-40px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <span class="h6 float-right px-4 pt-5 text-sm d-flex justify-content-center align-items-center">
                <h6>{{ $t("general.total") }}</h6>
                : {{ totalList }}
              </span>
            </div>
          </div>

          <data-table
            :bordered="false"
            :borderless="true"
            :no-border-collapse="true"
            :hover="false"
            :outlined="false"
            :striped="false"
            :fields.sync="tableFields"
            :items="itemsProxy"
            :page.sync="page"
            :perPage.sync="per_page"
            :sortBy.sync="order"
            :sortDesc.sync="sort"
            :tableParentFields="[]"
            @hitBottom="onHitBottom"
            @onChangeSearchFilter="onChangeSearchFilter"
            @onClickDelete="onClickDelete"
            @rowClicked="onRowClicked"
            @onGetData="onClickEdit"
            :infiniteId="infiniteId"
          ></data-table>
          <div v-if="total_quantity" class="row">
            <div class="col-12">
              <span class="h4 float-right mr-4">
                <b>{{ $t("packing_list.total_quantity") }} </b>: {{ total_quantity }} /
                <b>{{ $t("packing_list.box_quantity") }}:</b>
                {{ total_box_quantity }}</span
              >
            </div>
          </div>

          <div
            class="fixed"
            style="bottom: 20px; right: 40px"
            v-if="isUserGranted('PackingList', ['create'], false)"
          >
            <div class="w-60px h-60px" @click="createNewItem()">
              <span
                v-html="
                  getIconByKey('icons.waybill.waybill_entry', {
                    class: 'w-60px h-60px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moduleUser, {
  GENERAL_SETTING,
  MODULE_NAME as MODULE_USER,
} from "@/core/services/store/user.module";
import store from "@/core/services/store";
import {
  FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE,
  FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE,
} from "@/core/storage/storage-names";

import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import FileInput from "@/assets/components/inputs/FileInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import {
  CREATE_ITEM,
  CURRENT_ITEM,
  DELETE_ITEM_BY_ID,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ERROR,
  SET_ITEMS,
  SET_LOADING,
  SUCCESS,
  UPDATE_ITEM_BY_ID,
  FILTER,
  SET_FILTER,
  LIST_EXPORT,
} from "@/core/services/store/packing-list/packing_list_production.module";

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import KTCard from "@/view/content/Card.vue";
import $ from "jquery";
import * as _ from "lodash";
import draggable from "vuedraggable";

const _MODULE_USER = MODULE_USER;

export default {
  name: "PackingListIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    ButtonWithIcon,
    DateFilter,
    KTCard,
    FileInput,
    draggable,
    DatePickerInput,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
      packing: CURRENT_ITEM,
      filter: FILTER,
    }),
    expenseCoefficient() {
      let expenseCoefficient = 10;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          expenseCoefficient = settings.fabric_request.expense_coefficient;
        }
      }
      console.log("expenseCoefficient",expenseCoefficient);
      return expenseCoefficient;
    },
    settings() {
      return this.$store.getters[_MODULE_USER + "/" + GENERAL_SETTING];
    },
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    tableFields() {
      let fields = [];
      let self = this;
      for (let [key, value] of Object.entries(self.modelTableItem[0].table_fields)) {
        if (value.status === true) {
          let options = [];
          if (key === "status_packing") {
            for (const [key, value] of Object.entries(this.packingListStatuses)) {
              options.push({
                value: key,
                text: value,
              });
            }
          }

          fields.push({
            key: key,
            value: this.filter && this.filter.hasOwnProperty(key) ? this.filter[key] : "",
            label: this.$t(value.name),
            sortable: value.status,
            sort: value.sort,
            class: "text-center border border-top-0 border-left-0",
            tdClass: "cursor-pointer",
            searchable: value.searchable,
            searchable_type: value.searchable_type,
            searchable_options: options,
          });
        }
      }
      return _.sortBy(fields, ["sort"]);
    },
    isValidToCreate() {
      let returnResult = false;

      this.orderInformations.forEach((item) => {
        if (item.box_quantity && item.d_any && item.in_package && item.box_weight) {
          returnResult = true;
          return;
        }
      });

      this.orderInformations.forEach((item) => {
        let orderAmount = Number(item.total_amount) + (Number(item.total_amount) * this.expenseCoefficient) / 100;
        let amount_entered = Number(item.total_amount) - Number(item.amount);
        if (
          Number(item.box_quantity) * Number(item.in_package) +
            Number(item.broken_parcel) +
            Number(amount_entered) >
          orderAmount
        ) {
          this.sweetAlertError(this.$t("packing_list.amount_error"));
          returnResult = false;
          return;
        }

        if (typeof item.upload_date == "function") {
          returnResult = false;
          return;
        }

        if (item.box_quantity && (!item.d_any || !item.in_package || !item.box_weight)) {
          returnResult = false;
          return;
        }

        if (item.in_package && (!item.box_quantity || !item.d_any || !item.box_weight)) {
          returnResult = false;
          return;
        }

        if (item.box_weight && (!item.box_quantity || !item.in_package || !item.d_any)) {
          returnResult = false;
          return;
        }

        if (item.d_any && (!item.box_quantity || !item.in_package || !item.box_weight)) {
          returnResult = false;
          return;
        }
      });

      if (this.form.supplier_company_id == null) {
        returnResult = true;
        return;
      }

      return returnResult;
    },
    taskItemProxy: {
      get() {
        if (this.packing) {
          this.onClickEdit(this.packing);
        }
        return this.packing;
      },
      set(value) {
        // this.setExpense(value);
      },
    },
    //     totalList(){
    // let total = 0;
    //         if (!this.items || this.items.data === null || this.items.data === undefined)
    //           return total;

    //         let items = this.items;

    //         this.total_quantity = items.total;
    //         this.total_box_quantity = items.box_quantity;

    //         if (items.data) {
    //           items.data.forEach((item) => {
    //             let totalAmount = 0;
    //             item.data.forEach((subItem, index) => {
    //               totalAmount +=
    //                 Number(subItem.in_package || 0) * Number(subItem.box_quantity || 0) +
    //                 Number(subItem.broken_parcel || 0);
    //             });
    //             total+=Number(totalAmount);
    //           });
    //         }

    //         return total;
    //     },
    itemsProxy: {
      get() {
        let results = [];
        if (!this.items || this.items.data === null || this.items.data === undefined)
          return results;

        let items = this.items;

        this.total_quantity = items.total;
        this.total_box_quantity = items.box_quantity;

        if (items.data) {
          items.data.forEach((item) => {
            let tmpItem = {
              id: item.id,
              date: moment(item.created_at).format(MOMENT_SYSTEM_DATE_FORMAT),
              order_number: item.orderable ? item.orderable.order_number : "",
              order_id: item.orderable ? item.orderable.id : "",
              status_id: item.status.translations[0].name,
              status_id_number: item.status.id,
              actions: { get: true },
            };

            let bodySizeArray = [];
            let uploadDateArray = [];
            let colorNameArray = [];
            let colorNumberArray = [];
            let totalAmount = 0;
            let requestedAmount = 0;
            let totalWeight = 0;
            let totalInPackage = [];
            item.data.forEach((subItem, index) => {
              bodySizeArray.push(subItem.body_size);
              let uploadDate =
                typeof subItem.upload_date !== "undefined"
                  ? moment(subItem.upload_date).format(MOMENT_SYSTEM_DATE_FORMAT)
                  : this.$t("general.not_exist ");
              uploadDateArray.push(
                "(" +
                  this.$t("following.size") +
                  ":" +
                  subItem.body_size +
                  "/" +
                  this.$t("general.amount") +
                  ":" +
                  subItem.amount +
                  "/" +
                  this.$t("general.date") +
                  ":" +
                  uploadDate +
                  ")"
              );
              totalAmount +=
                Number(subItem.in_package || 0) * Number(subItem.box_quantity || 0) +
                Number(subItem.broken_parcel || 0);
              totalWeight += Number(subItem.box_weight || 0);
              requestedAmount += Number(subItem.amount || 0);

              colorNameArray.push(subItem.color_name);
              colorNumberArray.push(subItem.color_number);
              tmpItem.box_weight = totalWeight;
              tmpItem.amount = requestedAmount;
              tmpItem.total = totalAmount;
              tmpItem.body_size = bodySizeArray.join(",");
              tmpItem.upload_date = uploadDateArray.join(",");
              tmpItem.po_number = item.orderable ? item.orderable.po_number : "";
              tmpItem.order_size_and_color_id = subItem.order_size_and_color_id;
              tmpItem.supplier_company_name = item.supplier_company
                ? item.supplier_company ? item.supplier_company.name : '-'
                : item.user ?  item.user.name : '-';

              tmpItem.status = item.status.translations[0].name;
              tmpItem.createdAt = subItem.created_at
                ? moment(subItem.created_at, LARAVEL_DATE_FORMAT).format(
                    MOMENT_SYSTEM_DATE_FORMAT
                  )
                : "";

              totalInPackage.push(
                "(" +
                  subItem.body_size +
                  " : " +
                  subItem.color_name +
                  " - " +
                  subItem.in_package +
                  ")\n\r"
              );
            });

            tmpItem.color_number = _.uniq(colorNumberArray).join("-");
            tmpItem.color_name = _.uniq(colorNameArray).join("-");
            tmpItem.in_package = totalInPackage.join(",");

            results.push(tmpItem);
          });
        }

        return results;
      },
      set(value) {
        // this.setItems(value);
      },
    },
  },
  data() {
    return {
      infiniteId: 1,
      fieldsToViewEdit: [],
      editable: true,
      isDragging: false,
      sort: "desc",
      order: "id",
      delayedDragging: false,
      totalList: 0,
      modelTableItem: [
        {
          user_id: null,
          model_name: null,
          table_fields: {},
        },
      ],
      orderInformations: [],
      colorInformations: [],
      url: "api/following-production/packing-list",
      orderUrl: "api/following-production/packing-list/orders",
      isEditing: false,
      isCreating: false,
      search: null,
      status_id: null,
      page: 1,
      per_page: 10,
      total_quantity: 0,
      total_box_quantity: 0,
      form: {
        id: null,
        order_id: null,
        order_size_and_color_id: [],
        supplier_company_id: null,
      },
      items: [],
      orderOptions: [],
      orderOptionsRaw: [],
      itemId: this.$route.params.packing_id,
      activeItem: null,
      packingListStatuses: [],
      supplierCompanies: [],
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS,
      setError: SET_ERROR,
      setFilter: SET_FILTER,
    }),
    filterResult() {
      this.getTotalList();
      this.onHitBottom(null);
    },
    cancelClick() {
      if (this.itemId) {
        this.itemId = null;
        setTimeout(this.onHitBottom, 500);
      }
      this.isCreating = false;
      this.isEditing = false;
      this.clearFormItems();
    },
    exportProductPackingListTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);

      this.$store
        .dispatch(LIST_EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "packing_list_product" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    createNewItem() {
      this.orderInformations = [];
      this.isCreating = true;
      this.form.order_size_and_color_id = [];
      this.form.order_id = null;

      if (this.itemId) {
        this.itemId = null;
        setTimeout(this.onHitBottom, 500);
      }
    },
    onChangeSearchFilter(key, value) {
      this.$set(this.filters, key, value);
    },
    openCreateNewESCPage() {
      this.$router.push({
        name: "time-sheet.create",
        params: { type: 1 },
        query: this.$route.query,
      });
    },
    onRowClicked(item) {
      if (!this.isUserGranted("FollowingProduct", ["update"])) {
        return false;
      }

      if (item.status_id_number == 27 || item.status_id_number >= 27) {
        if (!this.isUserGranted("Staff", ["followingExport", "superAdmin"])) {
          this.sweetAlertError("general.you_have_no_authorized_to_do_this_action");
          return;
        }
      }

      this.$router.push({
        name: "following_production.packing_list.edit",
        params: { packing_id: item.id },
      });
    },
    getTableItem() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/model_table",
          filters: {
            model_name: "packing_list",
          },
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            for (let i = 0; i < result.data.length; i++) {
              self.modelTableItem = result.data;
              self.modelTableItem[i].table_fields = JSON.parse(
                result.data[i].table_fields
              );
            }
            for (const [key, value] of Object.entries(
              self.modelTableItem[0].table_fields
            )) {
              self.fieldsToViewEdit.push({
                name: this.$t(value.name),
                sort: value.sort,
                type: value.type,
                type_status: value.status,
                key: key,
                searchable: !!value.searchable,
                searchable_type: value.searchable_type,
                searchable_options: value.searchable_options,
              });
              self.fieldsToViewEdit = _.sortBy(self.fieldsToViewEdit, ["sort"]);
            }
          }
        });
    },
    getTotalList() {
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "amount_sum", true);
      this.$store
        .dispatch(GET_ITEMS, {
          url: self.url,
          filters: filters,
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          this.totalList = Number(result.data.sum_amount).toLocaleString('tr-Tr');
        });
    },
    orderList() {
      this.modelTableItem[0].table_fields = this.modelTableItem[0].table_fields.sort(
        (one, two) => {
          return one.sort - two.sort;
        }
      );
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (!relatedElement || !relatedElement.status) && !draggedElement.status;
    },
    openPopToSettingTable() {
      this.$modal.show("open_to_settings_table");
    },
    closeModal() {
      this.$modal.hide("open_to_settings_table");
    },
    onSubmitToSaveSettingTable() {
      let self = this;
      let json = {};

      for (let i = 0; i < this.fieldsToViewEdit.length; i++) {
        json[this.fieldsToViewEdit[i].key] = {
          name: this.fieldsToViewEdit[i].name,
          type: this.fieldsToViewEdit[i].type,
          status: this.fieldsToViewEdit[i].type_status,
          sort: i,
          searchable: !!this.fieldsToViewEdit[i].searchable,
          searchable_type: this.fieldsToViewEdit[i].searchable_type,
        };
      }

      let contents = {
        model_name: "packing_list",
        table_fields: json,
      };
      let payload = {
        url: "api/model_table/" + this.modelTableItem[0].id,
        id: this.id,
        contents: contents,
      };
      this.$store.dispatch(UPDATE_ITEM_BY_ID, payload).then((result) => {
        if (result.status) {
          this.$modal.hide("open_to_settings_table");
          self.sweetAlertSuccess(self.$t("general.successfully_created"));
          this.fieldsToViewEdit = [];
          this.getTableItem();
        } else {
          self.sweetAlertError(self.$t("general.internal_error"));
        }
      });
    },
    onDocumentChange(payload) {
      let id = payload.id;
      let image = payload.file;
      let reader = new FileReader();
      reader.onload = function (e) {
        $("#" + id).attr("src", e.target.result);
      };
      reader.readAsDataURL(image); // convert to base64 string
    },
    openUploadImage(ref) {
      this.$refs[ref][0].openFileInput();
    },
    onOrderSelectAfterClearItems() {
      this.colorInformations = [];
      this.form.order_size_and_color_id = [];
      this.form.supplier_company_id = 0;
      this.supplierCompanies = [];
      this.orderInformations = [];
    },
    onOrderColorSelect(value) {
      let itemIds = value.newValue;
      let selectedItem = this.orderOptionsRaw.find(
        (item) => item.id === +this.form.order_id
      );
      let results = [];
      selectedItem.order_size_and_colors
        .filter((orderSizeAndColorItem) =>
          itemIds.includes(orderSizeAndColorItem.id.toString())
        )
        .forEach((item) => {
          let bodySizes = _.values(JSON.parse(item.body_sizes));
          bodySizes.forEach((subItem) => {
            let tmpItem = subItem;
            tmpItem.order_size_and_color_id = item.id;
            tmpItem.order_number = selectedItem.order_number;
            tmpItem.po_number = item.po_number;
            tmpItem.color_number = item.color_number;
            tmpItem.color_name = item.color_name;
            tmpItem.body_size = subItem.name;
            tmpItem.amount = subItem.amount;
            tmpItem.total_amount = subItem.total_amount;
            tmpItem.d_any = item.d_any;
            tmpItem.upload_date = subItem.upload_date
              ? moment(subItem.upload_date, LARAVEL_DATE_FORMAT).format(
                  MOMENT_SYSTEM_DATE_FORMAT
                )
              : moment();
            tmpItem.box_quantity = item.box_quantity;
            tmpItem.broken_parcel = item.broken_parcel;
            tmpItem.in_package = item.in_package;
            tmpItem.box_weight = item.box_weight;
            tmpItem.image = item.image;
            tmpItem.broken_total_parcel = tmpItem.broken_total_parcel
              ? item.broken_parcel > 0
                ? Number(item.box_quantity) + 1
                : item.broken_parcel
              : item.broken_parcel;
            results.push(tmpItem);
          });
        });
      this.orderInformations = results;
    },
    deleteClick() {
      let self = this;
      if (self.isUserGranted("PackingList", ["delete"])) {
        self.sweetAlertConfirm(
          this.$t("general.are_you_sure"),
          function (self) {
            let payload = {
              url: "api/following-production/packing-list/" + id,
              id: id,
            };
            self.$store.dispatch(DELETE_ITEM_BY_ID, payload);
          },
          () => {}
        );
      }
    },
    storeData() {
      let self = this;

      if (self.isUserGranted("PackingList", ["create"])) {
        self.isEditing = true;
        let orderInformations = this.orderInformations;
        let supplierCompanyId = this.form.supplier_company_id;
        let orderSizeAndColor = this.form.order_size_and_color_id;
        let form = new FormData();
        let transactions = [];

        orderInformations.forEach((item) => {
          let tmpItem = {};
          tmpItem.order_size_and_color_id = item.order_size_and_color_id;
          tmpItem.order_number = item.order_number;
          tmpItem.po_number = item.po_number;
          tmpItem.color_number = item.color_number;
          tmpItem.color_name = item.color_name;
          tmpItem.body_size = item.body_size;
          tmpItem.amount = item.amount || 0;
          tmpItem.d_any = item.d_any || 0;
          tmpItem.upload_date = moment(item.upload_date).format(LARAVEL_DATE_FORMAT);
          tmpItem.box_quantity = item.box_quantity || 0;
          tmpItem.broken_parcel = item.broken_parcel || 0;
          tmpItem.in_package = item.in_package || 0;
          tmpItem.box_weight = item.box_weight || 0;
          tmpItem.broken_total_parcel =
            item.broken_parcel > 0 ? Number(item.box_quantity) + 1 : item.broken_parcel;
          transactions.push(tmpItem);
        });

        this.createFormDataForList(form, "data", transactions);

        orderInformations.forEach((item, index) => {
          form.append("data[" + index + "][image]", item.image);
        });
        form.append("order_id", self.form.order_id);
        form.append("supplier_company_id", supplierCompanyId);
        form.append("order_size_and_color_id", orderSizeAndColor);

        let payload = {
          url:
            this.form.id == null
              ? "api/following-production/packing-list"
              : "api/following-production/packing-list/" + this.form.id + "/update",
          id: this.form.id,
          contents: form,
          returnType: "stateless",
          successMessage: this.$t(
            this.form.id == null
              ? "general.successfully_created"
              : "general.successfully_updated"
          ),
        };

        this.$store.dispatch(CREATE_ITEM, payload).then((response) => {
          if (response.status) {
            self.clearFormItems();
            self.getOrderOptions();

            if (this.itemId) {
              this.itemId = null;
            }

            setTimeout(this.onHitBottom, 500);
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
          self.isEditing = false;
        });
      }
    },
    clearFormItems() {
      this.form.id = null;
      this.form.order_id = null;
      this.form.order_size_and_color_id = [];
      this.form.supplier_company_id = null;
      this.orderInformations = [];
      this.isEditing = false;
      this.isCreating = false;
      this.sort = "desc";
      this.order = "id";
    },
    clickImage(index) {
      let ref = "file" + index;
      this.$refs[ref][0].click();
    },
    onHitBottom($state) {
      this.infiniteId++;
      let self = this;
      if (self.isUserGranted("PackingList", ["viewAny"])) {
        let filters = _.cloneDeep(this.filters);
        this.$set(
          filters,
          "start_date",
          moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(
          filters,
          "end_date",
          moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
        );
        this.$set(filters, "order", this.order);
        this.$set(filters, "sort", this.sort);
        localStorage.setItem(
          FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE,
          moment(this.filters.start_date)
        );
        localStorage.setItem(
          FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE,
          moment(this.filters.end_date)
        );

        self.$store
          .dispatch(HANDLE_INFINITE_SCROLL, {
            filters: filters,
            url: self.url,
            $state: $state,
          })
          .then((result) => {
            if (result.status) {
              self.items = result.data;
              if ($state) {
                $state.loaded();
              }
              // if (self.itemId) {
              //   self.getPackingListItem();
              // }
            } else {
              if ($state) {
                $state.complete();
              }
            }
          });
      }
    },
    imageChange(index) {
      let ref = "file" + index;
      this.orderInformations[index].image = this.$refs[ref][0].files[0];
      let imageHolder = $("#image_holder-" + index);
      imageHolder.html(this.orderInformations[index].image.name);
    },
    onClickDelete(id) {
      let self = this;
      if (self.isUserGranted("PackingList", ["delete"])) {
        self.sweetAlertConfirm(
          this.$t("general.are_you_sure"),
          function (self) {
            let payload = {
              url: "api/following-production/packing-list/" + id,
              id: id,
            };
            self.$store.dispatch(DELETE_ITEM_BY_ID, payload);
            this.getTotalList();
          },
          () => {}
        );
      }
    },
    fixItemsArray(items) {
      let results = [];
      let orderSizeAndColorIds = [];
      items.forEach((orderItem) => {
        orderItem.data.forEach((subItem, index) => {
          orderSizeAndColorIds.push(subItem.order_size_and_color_id.toString());
          results.push({
            id: orderItem.id,
            order_number: orderItem.orderable ? orderItem.orderable.order_number : "",
            po_number: orderItem.orderable ? orderItem.orderable.po_number : "",
            order_id: orderItem.orderable ? orderItem.orderable.id : "",
            order_size_and_color_id: Number(subItem.order_size_and_color_id),
            d_any: subItem.d_any,
            color_number: subItem.color_number,
            color_name: subItem.color_name,
            body_size: subItem.body_size,
            box_quantity: subItem.box_quantity,
            upload_date:
              orderItem.upload_date !== "0000-00-00"
                ? moment(orderItem.upload_date).format(LARAVEL_DATE_FORMAT)
                : moment().format(LARAVEL_DATE_FORMAT),
            broken_parcel: subItem.broken_parcel,
            in_package: subItem.in_package,
            amount: subItem.amount,
            total: subItem.in_package * subItem.box_quantity + subItem.broken_parcel,
            box_weight: subItem.box_weight,
            image: subItem.image,
            image_link: orderItem.image_link[index],
            image_link_column: true,
            status: subItem.status,
            createdAt: subItem.created_at
              ? moment(subItem.created_at, LARAVEL_DATE_FORMAT).format(
                  MOMENT_SYSTEM_DATE_FORMAT
                )
              : "",
            actions: { get: true },
          });
        });
      });

      return {
        results: results,
        orderSizeAndColorIds: orderSizeAndColorIds,
      };
    },
    onClickEdit(item) {
      let self = this;

      if (self.isUserGranted("PackingList", ["update"])) {
        let results = [];
        let orderSizeAndColorIds = [];
        let items = this.activeItem || this.items.data;
        if (items) {
          let methodResults = this.fixItemsArray(items);
          results = methodResults.results;
          orderSizeAndColorIds = methodResults.orderSizeAndColorIds;
        }
        self.orderInformations = results.filter(
          (subItem) =>
            +subItem.id === +item.id &&
            orderSizeAndColorIds.includes(subItem.order_size_and_color_id.toString())
        );
        self.form.id = item.id;
        self.form.order_id = item.order_id;
        self.form.order_size_and_color_id = _.uniq(orderSizeAndColorIds);
        self.isEditing = true;
        let selectedData = {
          newValue: [item.order_id],
        };

        self.onOrderSelect(selectedData);
        setTimeout(() => {
          self.orderInformations.forEach((item, index) => {
            $(`#preview-content-image-${index}`).attr("src", item.image_link);
          });
        }, 100);
      }
    },
    getOrderOptions() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.orderUrl,
          acceptPromise: true,
          returnType: "stateless",
        })
        .then((result) => {
          if (result.status) {
            self.orderOptions = self.convertArrayToObjectByKey(
              result.data,
              "id",
              "order_number"
            );
            self.orderOptionsRaw = result.data;
          }
        });
    },
    resetFilters() {
      this.setFilter({
        status_id: null,
        search: "",
        start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
      });
    },
    getPackingListItem() {
      let self = this;
      let parameterPackingID = self.itemId;
      if (parameterPackingID) {
        let payload = {
          url: "api/following-production/packing-list/" + parameterPackingID,
          id: this.id,
          redirectRouteOnNotFound: null,
        };
        this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, payload).then((response) => {
          if (response.status) {
            let item = response.data;
            self.activeItem = [item];
            item.order_id = item.orderable_id;
            self.onClickEdit(item);
          }
        });
      }
    },
    getPackingListStatus() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/statuses",
          filters: {
            type_id: 5,
          },
          acceptPromise: true,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.packingListStatuses = self.convertArrayToObjectByKey(
              result.data,
              "id",
              "translations.0.name"
            );
          }
        });
    },
    getSupplierCompanies(orderId) {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/following-production/supplier-companies",
          filters: { order_id: orderId },
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.supplierCompanies = self.convertArrayToObjectByKey(
              result.data,
              "id",
              "name"
            );
          }
        });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.management.packing_list") },
    ]);

    if (!this.isUserGranted("Staff", ["planner"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.start_date = localStorage.hasOwnProperty(
      FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE
    )
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_PACKING_LIST_START_DATE))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);
    this.filters.end_date = localStorage.hasOwnProperty(
      FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE
    )
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_PACKING_LIST_END_DATE))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.getPackingListStatus();
    this.getTableItem();
    this.getTotalList();
    this.getOrderOptions();

    if (this.itemId) {
      setTimeout(this.getPackingListItem, 500);
    } else {
      setTimeout(this.onHitBottom, 500);
    }
  },
  watch: {
    per_page: function (newValue, oldValue) {
      this.page = 1;
      this.$store.dispatch(GET_ITEMS, { url: this.url, filters: this.filters });
    },
    page: function (newValue, oldValue) {
      this.$store.dispatch(GET_ITEMS, { url: this.url, filters: this.filters });
    },
    sort() {
      this.onHitBottom();
    },
    order() {
      this.onHitBottom();
    },
    "form.order_id"(newValue, oldValue) {
      this.onOrderSelectAfterClearItems();
      let selectedItem = this.orderOptionsRaw.find((item) => item.id === +newValue);
      this.getSupplierCompanies(newValue);
      this.colorInformations = this.convertArrayToObjectByKey(
        selectedItem.order_size_and_colors,
        "id",
        "full_name"
      );
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
